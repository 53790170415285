import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from './components/Pages/Home'
import Services from './components/Pages/Services';
import Footer from './components/Footer/Footer';
import ContactUs from './components/Contactus/ContactUs';


function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/contact-us' element={<ContactUs/>} />
          
        </Routes>

        <Footer />
      </Router>

    </div>
  );
}

export default App;
