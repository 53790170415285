import React from "react";
import InformationPanelReverse from "../About Reverse/InformationPanelReverse";
import InformationPanel from "../About/InformationPanel";
import Hero from "../Hero/Hero";
import './Home.css';
import Footer from '../Footer/Footer'

function Home() {
  return (
    <>
      <Hero />
      
      <div className="heading_wrapper">
        <h1 className="header_section">INFORMATION</h1>
        <hr className="small" />
        <InformationPanel />
        <InformationPanelReverse/>
      </div>
      
    </>
  );
}

export default Home;
