import React from 'react'


import ButtonEnergy from '../Button_Energy/ButtonEnergy';
import './Hero.css';
import logo from './logo_nav.svg'
import Backdrop from './backdrop.png'

function Hero() {
  return (
    <div className='hero-container'>
      <img className='backdrop' src={Backdrop} alt='backdrop'/>
      <div className='hero-container-2'>
      <img src={logo} alt='logo' className='logo_hero'/>
      <h1 className='Hero_Heading'>EDWARDMED</h1>
      <div className='Hero_Heading_2'>SUPERIOR MEDICAL EQUIPMENT FOR SUPERIOR PATIENT CARE</div>
      
      <div className='hero-btns'>
        <ButtonEnergy
          url='/services'
          title='SERVICES'
          
        >
        </ButtonEnergy>

        <ButtonEnergy
          url='/ContactUs'
          title='CONTACT US'
          
        >
        </ButtonEnergy>
        </div>
      </div>
    </div>
  )
}

export default Hero