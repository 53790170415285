import React from 'react'
import image from './choice.jpg'
import './InformationPanelReverse.css'
function InformationPanelReverse() {
  return (
    <>
        <div className='information_Wrapper_rev'>
            <div className='text_information_rev'>
                <h1 className='title_information_rev'>
                    SUPPLIER OF CHOICE
                </h1>
                With attention to service and customer relations, you can be assured that Edwardmed Medical is your supplier of choice. We have a dedicated team to attend to all your needs with emphasis on service and support. All our products carry international certifications, to ensure quality standards across all furniture, equipment and medical consumables.Our aim is satisfying all our customers' needs, so if we don't have it, we will source it for you!
            </div>

            <div className='image_wrapper_rev'>
                <img src={image} alt='bak' className='image_information_rev'/>
            </div>
        </div>
    </>
  )
}

export default InformationPanelReverse