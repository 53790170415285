import React from "react";
import InformationPanelServices from "../About Services/InformationPanelServices";

import "./Services.css";
import IconPackDisplay from '../IconPack/IconPackDisplay.js'

function Services() {
  return (
    <>
      <div className="main_wrapper">
        <InformationPanelServices />
        <IconPackDisplay/>
      </div>
    </>
  );
}

export default Services;
