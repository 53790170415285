import React from 'react'
import './Contactus.css'

function ContactUs() {
    return (
        <div className='contactus_wrapper'>
            <div className='contactus_text_area'>
                <div className='heading_contactus'>
                    CONTACT US
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Contact Person
                    </div>
                    <div className='large_text'>
                    
                    Edward Masingi
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Phone Number
                    </div>
                    <div className='large_text'>
                    0725939405
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Email Address
                    </div>
                    <div className='large_text'>
                    Edwardmedsupplies@gmail.com
                    </div>
                </div>

                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Physical Address
                    </div>
                    <div className='large_text'>
                    6 Hulley road , isando, Ekurhuleni Gauteng 1609
                    </div>
                </div>


            </div>
        </div>
    )
}

export default ContactUs