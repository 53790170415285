import React from 'react'
import image from './services.jpg'
import './InformationPanelServices.css'
function InformationPanelServices() {
  return (
    <>
        <div className='information_Wrapper_services'>
            <div className='text_information_services'>
                <h1 className='title_information_services'>
                EDWARDMED SERVICES OFFERED
                </h1>
                Edwardmed is a proudly South African company focusing on the sales and distribution of Medical Furniture and Hospital Equipment. The business was built on medical expertise, excellent client service and the pursuit of medical- and clinical innovation. Today, this desire to constantly improve and grow is an integral part of the Edwardmed culture and the brand is now firmly established in the South African Medical supply chain.
            </div>

            <div className='image_wrapper_services'>
                <img src={image} alt='bak' className='image_information_services'/>
            </div>
        </div>
    </>
  )
}

export default InformationPanelServices