import React from 'react'
import image from './image_1.jpg'
import './InformationPanel.css'
function InformationPanel() {
  return (
    <>
        <div className='information_Wrapper'>
            <div className='text_information'>
                <h1 className='title_information'>
                    ABOUT
                </h1>
                Edwardmed is a privately owned South African company which has recently been founded. Edwardmed Distributors' customer base is extensive, throughout Africa. Our main field of expertise lies in the supply of medical goods to institutions and patients as well as the supply of medicine to registered vendors only.
            </div>

            <div className='image_wrapper'>
                <img src={image} alt='bak' className='image_information'/>
            </div>
        </div>
    </>
  )
}

export default InformationPanel